import React from 'react'
import { Link } from 'gatsby'
import Typist from 'react-typist'
import Helmet from 'react-helmet'


function Type(props) {
    return <Typist avgTypingDelay={10} stdTypingDelay={0}  cursor = {{hideWhenDone: true, hideWhenDoneDelay: 0}} >{props.children}</Typist>
}



function LinkABOUT(props) {
  return <CLink to={"/about"} location={props.location}>{props.children}</CLink>
}

function LinkHOME(props) {
  return <CLink to={"/"} location={props.location}>{props.children}</CLink>
}

function LinkWORK(props) {
  return <CLink to={"/work"} location={props.location}>{props.children}</CLink>
}

function LinkCALLS(props) {
  return <CLink to={"/calls"} location={props.location}>{props.children}</CLink>
}


function CLink(props) {
    if (props.to === props.location.pathname + props.location.search)  // if the link is where we currently are....
      {
      return <Link className="active" to={props.to} >{props.children }</Link>  // paint it blue, otherwise...
      }
      else {
        return <Link to={props.to + props.location.search}>{props.children }</Link>  // show it normally
      }
}






function LinkEN(props) {
  return <Link to={props.location.pathname + "?en"} >{props.children}</Link>
}

function LinkDE(props) {
  return <Link to={props.location.pathname} >{props.children}</Link>
}







function English(props) {

  if (props.Language === "?en" )
    {return <div className="text">{ props.children }</div>}
    else
      {return null }
}


function Deutsch(props) {

  if (props.Language !== "?en")
    {return <div className="text">{ props.children }</div>}
  else
    {return null }
}




function Page(props) {
  if (props.location.search === "?en") {
  return <div className="page_en">{ props.children }</div>
} else {
  return <div className="page_de">{ props.children }</div>
}

}



function Videovm(props) {
  return <div className="embed-container">
  <iframe title="unique_title" src={"https://player.vimeo.com/video/" + props.link + "?autoplay=1&loop=1&autopause=0&background=1&mute=1&sidedock=0"} style={{overflow: "hidden",height:"100%",width:"100%"}} height="1080" width="1920"  allow="autoplay"  frameBorder="0"  allowFullScreen></iframe>
  </div>
}



function SiteTitle(props) {
  return  <Helmet>
  <title>{props.string}</title>
  </Helmet>
}




function Back_To_All_Projects(props) {
  return <div className="comment">
  <English Language={props.location.search}>
        <Type>

        <LinkWORK location={props.location}>See more projects</LinkWORK> or <LinkHOME location={props.location}>go home</LinkHOME>.

        </Type>
  </English>




 <Deutsch Language={props.location.search}>
        <Type>
        <LinkWORK location={props.location}>Zurück zur Projektübersicht</LinkWORK> oder <LinkHOME location={props.location}>zum Anfang</LinkHOME>.
        </Type>
  </Deutsch>

</div>
}



function FirstRenderTypist(props) {

  if (props.rendered != "true")
    {return <Type>{ props.children }</Type>}
  else
    {return <div> { props.children }</div> }
}



export {Deutsch, English, Type, LinkABOUT, LinkHOME, LinkWORK, LinkDE, LinkEN, CLink, Page, Videovm, SiteTitle, Back_To_All_Projects, FirstRenderTypist}
